import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import BlogListing from '../components/BlogListing'

const BlogIndex = ({ data }) => {
  const entries = data.craftGql.entries
  return (
    <Layout>
      <SEO
        title={`Blog`}
        description={`Spontaneously updated notes from the field.`}
        additionalLink={[
          {
            rel: `alternate`,
            type: `application/rss+xml`,
            title: `RSS`,
            href: `https://workingconcept.com/blog/rss.xml`,
          },
          {
            rel: `alternate`,
            type: `application/atom+xml`,
            title: `Atom`,
            href: `https://workingconcept.com/blog/atom.xml`,
          },
          {
            rel: `alternate`,
            type: `application/json`,
            title: `JSON`,
            href: `https://workingconcept.com/blog/feed.json`,
          },
        ]}
      />
      <section role="main" className={`mb-32 px-8`}>
        {entries.map((entry, index) => (
          <BlogListing
            title={entry.title}
            uri={entry.uri}
            date={entry.postDate}
            first={index === 0}
            commentsEnabled={entry.commentsEnabled}
            key={index}
          />
        ))}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    craftGql {
      entries(section: "blog") {
        title
        uri
        postDate
        author {
          fullName
        }
        ... on CraftGQL_blog_blog_Entry {
          commentsEnabled
        }
      }
    }
  }
`

export default BlogIndex
